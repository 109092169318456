<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label-for="special"
          >
            <b-form-checkbox
              v-model="special"
              class="custom-control-primary"
            >
              Gestión
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('roles.store') : accessPermissions('roles.update') && name !== 'super_administrator'"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            :to="{ name: 'settings-roles' }"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import router from '@/router'
import RolesServices from '../services/RolesServices'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      name: '',
      special: null,
      required,
    }
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.name = ''
      this.special = null
      this.$refs.formValidation.reset()
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('roles.show')) {
        await RolesServices.handleEdit(this.id).then(response => {
          this.name = response.data.data.name
          this.special = response.data.data.special === 1 ? true : false
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const data = {
            name: this.name,
            special: this.special ? 1 : 0,
          }

          if (this.id === undefined && this.accessPermissions('roles.store')) {
            RolesServices.handleStore(data).then(response => {
              if (response.data.data) {
                this.swal('El role ha sido registrado correctamente', 'success')
                this.clearForm()
                router.push({ name: 'settings-roles-edit', params: { id: response.data.data.rol_id } })
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('roles.update')) {
            RolesServices.handleUpdate(this.id, data).then(response => {
              if (response.data.data) {
                this.swal('El role ha sido actualizado correctamente', 'success')
                this.clearForm()
                this.onHandleEdit()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
